
import Routes from "@/Routes";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import AuthStore from "@/store/AuthStore";
import AuthenticateRequest from "@/models/AuthenticateRequest";
import NotificationStore from "@/store/NotificationStore";
import SnackbarNotification, { SnackbarNotificationType } from "@/models/SnackbarNotification";
import AppStore from "@/store/AppStore";
import OrganizationStore from "@/store/OrganizationStore";
import Organization from "@/models/Organization";
import IAppTheme from '@/../../Main/src/themes/IAppTheme';

@Component({
  components: {
  },
})
export default class Signin extends Vue {
  accessCodeValidationInProgress = false;
  passwordVisible = false;
  accessCode = "";
  model: any = {
    email: "",
    password: "",
  };

  get rules(): { [key: string]: any; } {
    return {
      required: (v: any) => !!v || this.$t('generic.validation.required'),
    }
  }

  get useOrganizationAccessCodes(): boolean {
    return AppStore.features?.organizationAccessCodes?.enabled
      ? true 
      : false;
  }

  get appTheme(): IAppTheme {
    return AppStore.appTheme
  }

  get appThemeDark(): boolean {
    return this.appTheme.dark;
  }

  get appThemes(): IAppTheme[] {
    return AppStore.appThemes
  }

  get appName() {
    return this.appTheme?.appName ? this.appTheme?.appName : process.env.VUE_APP_NAME
  }

  get routeAccessCode() {
    return this.$route.params.accessCode;
  }

  get organization(): Organization | null {
    return OrganizationStore.organization;
  }

  @Watch("organization")
  onOrganizationChanged(val: Organization | null, oldVal: Organization | null){
    if (val == oldVal){
      return;
    }
    if (!val){
      return;
    }
    if (val.visitor){
      this.confirmOrganization();
    }
  }

  async onAccessCodeSubmitClick() {
    await this.submitAccessCode();
  }

  async submitAccessCode(){
    var organizationAccessCodeFormRef = this.$refs.organizationAccessCodeForm as any;
    if (!organizationAccessCodeFormRef){
      return false;
    }
    if (!organizationAccessCodeFormRef.validate()) {
      console.warn("Form validation failed");
      this.accessCodeValidationInProgress = false;
      return true;
    }
    this.accessCodeValidationInProgress = true;

    try {
      const validationRequest = new AuthenticateRequest(this.model.email, this.model.password)
      await OrganizationStore.getOrganizationByAccessCode(this.accessCode);
    } catch (e) {
      console.error(e);
      const message = this.$t("views.access.form.accessCode.submit.notFound").toString();
      NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
    }
    this.accessCodeValidationInProgress = false;
    return true;
  }

  async onCancelOrganizationSelectionClick(){
    await OrganizationStore.clearOrganization();
  }

  onConfirmOrganizationClick(){
    this.confirmOrganization();
  }

  confirmOrganization(){
    this.$router.push(Routes.signup);
  }


  created(){
    if (!this.useOrganizationAccessCodes){
      console.log("Organization access codes feature is not enabled, redirecting to root ...")
      this.$router.replace(Routes.root);
    }
  }

  async mounted(){
    if (this.routeAccessCode){
      this.accessCodeValidationInProgress = true;
      this.accessCode = this.routeAccessCode;
      console.log("Checking for access code ...")
      window.setTimeout(async() => {
        console.log("Validating access code ...")
        var submitDone = await this.submitAccessCode();
        if (!submitDone){
          console.log("Retrying submit ...")
          window.setTimeout(async() => {
            console.log("Validating access code (retry attempt 1)...")
            await this.submitAccessCode();
          }, 2000);
        }
      }, 1000);
    }
  }

}
