
import { Component, Vue, Watch } from 'vue-property-decorator';
import AuthStore from './store/AuthStore';
import PageLoading from "@/components/PageLoading.vue";
import Snackbar from "@/components/Snackbar.vue";
import AppEvents from './AppEvents';
import AppStore, { AppStoreModule } from './store/AppStore';
import { readFromLocalStorage } from './store';
import IAppTheme from '@/../../Main/src/themes/IAppTheme'
import LanguageStore from './store/LanguageStore';
import LocaleSelect from './components/LocaleSelect.vue';
import Organization from './models/Organization';
import OrganizationStore from './store/OrganizationStore';
@Component({
  components: {
    PageLoading,
    Snackbar,
    LocaleSelect
  },
 
})
export default class App extends Vue {
  authStateLoading = true;
  configLoading = true;

  currentYear = (new Date()).getFullYear().toString()


  get loading(){
    return this.authStateLoading || this.configLoading;
  }

  get appClient() {
    return this.appTheme?.providerName ? this.appTheme?.providerName : process.env.VUE_APP_CLIENT
  }

  get privacyPolicyUrl(): string | null {
    if (!this.appTheme.legal){
      return null;
    }
    const url = this.appTheme.legal.privacyPolicyUrl;
    return url ? url : null;
  }

  get cookiePolicyUrl(): string | null {
    if (!this.appTheme.legal){
      return null;
    }
    const url = this.appTheme.legal.cookiePolicyUrl;
    return url ? url : null;
  }

  get termsUrl(): string | null {
    if (!this.appTheme.legal){
      return null;
    }
    const url = this.appTheme.legal.termsUrl;
    return url ? url : null;
  }

  @Watch('isAuthenticated', { immediate: true })
  async onIsAuthenticatedChange(newVal: boolean) {
    if (newVal) {
      console.log("isAuthenticated changed to true")
      await this.redirectToFrontend();
    }
  }

  @Watch('authCheckInProgress')
  async onAuthCheckInProgressChange(newVal: boolean) {
    if (!newVal && !this.isAuthenticated) {
      this.authStateLoading = false;
    } else {
      this.authStateLoading = true;
    }
  }


  get currentLanguageCode(): string {
    return AppStore.currentLanguageCode;
  }

  @Watch("currentLanguageCode", {immediate: true})
  currentLanguageCodeChanged(val: string){
    if (val.startsWith("ar-")){
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
    console.log("currentLanguageCodeChanged: val", val);
    
    const newVuetifyLocale = val.split("-")[0].toLowerCase();
    console.log("newVuetifyLocale", newVuetifyLocale)
    this.$vuetify.lang.current = newVuetifyLocale;

    const newVueI18nLocale = val.toLowerCase();
    console.log("newVueI18nLocale", newVueI18nLocale)
    this.$i18n.locale = newVueI18nLocale;
  }

  get appTheme(): IAppTheme {
    return AppStore.appTheme
  }

  get appThemeDark(): boolean {
    return this.appTheme.dark;
  }

  get appThemes(): IAppTheme[] {
    return AppStore.appThemes
  }

  get isAuthenticated(): boolean {
    return AuthStore.isAuthenticated;
  }

  get authCheckInProgress(): boolean {
    return this.msalRedirectPromiseInProgress || this.googleRedirectPromiseInProgress;
  }

  get msalRedirectPromiseInProgress(): boolean {
    return AuthStore.msalRedirectPromiseInProgress;
  }

  get googleRedirectPromiseInProgress(): boolean {
    return AuthStore.googleRedirectPromiseInProgress;
  }

  get currentOrganization(): Organization | null {
    return OrganizationStore.organization;
  }

  get currentOrganizationIsVisitor(): boolean {
    if (!this.currentOrganization){
      return false;
    }
    return this.currentOrganization.visitor;
  }
  
  async redirectToFrontend() {
    const success = await AuthStore.getFrontendAccess();
    if (!success) {
      this.authStateLoading = false;
      return;
    }
    this.authStateLoading = true;
    let currentWindowLocation = window.location.origin;
    let currentWindowPath = window.location.pathname;
    let defaultRedirect = "/app";
    if (currentWindowPath.includes("/access/visitor_") || currentWindowPath.includes("/access/visitors-") || this.currentOrganizationIsVisitor){
      defaultRedirect = "/app/category/visitor_safety"
    }

    let redirectUrl = this.$route.query && this.$route.query.redirectUrl
      ? this.$route.query.redirectUrl.toString()
      : defaultRedirect;
    if (this.isFileUrl(redirectUrl)) {
      redirectUrl = defaultRedirect;
    }
    if(!currentWindowLocation.endsWith("/") && !redirectUrl.startsWith("/")){
      currentWindowLocation = currentWindowLocation + "/";
    }
    if(currentWindowLocation.endsWith("/") && redirectUrl.startsWith("/")){
      currentWindowLocation = currentWindowLocation.substring(1);
    }
    console.log('redirecting to app frontend: ' + currentWindowLocation + redirectUrl);
    (window as any).location.href = currentWindowLocation + redirectUrl;
  }

  async initApp() {
    this.configLoading = true;
    // console.log("Config loading ...")
    await AppStore.getConfigFeatures();
    // console.log("Config loaded.");
    await LanguageStore.getAllLanguages();
    this.configLoading = false;
  }

  async created() {
    this.$root.$on(AppEvents.REDIRECT_TO_FRONTEND, await this.redirectToFrontend);
    this.$root.$on(AppEvents.SIGNOUT_FROM_APP, await this.signoutFromApp);
    
    await this.initApp()

    if (!this.isAuthenticated && !this.authCheckInProgress) {
      this.authStateLoading = false;
    }
    
  }

  mounted() {
    (window as any)._loginApp = this;
  }

  beforeDestroy() {
    this.$root.$off(AppEvents.REDIRECT_TO_FRONTEND, this.redirectToFrontend);
    this.$root.$off(AppEvents.SIGNOUT_FROM_APP, this.signoutFromApp);
  }

  isFileUrl(url: string): boolean {
    let lastUrlPart = url.split('/').pop();
    return lastUrlPart ? lastUrlPart.indexOf('.') > -1 : false;
  }

  async signoutFromApp() {
    await AuthStore.resetFrontendAccess();

    if (AuthStore.isMsalAuthenticated) {
      await this.$msal.signOut();
    }

    if (AuthStore.isGoogleAuthenticated) {
      await this.$firebaseAuth.signOutWithGoogle();
    }

    if (AuthStore.isPasswordAuthenticated) {
      await AuthStore.resetAuthTokens();
    }

    (window as any).location.href = window.location.origin;
  }
}

