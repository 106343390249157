import { RouteConfig } from 'vue-router';
import Access from '@/views/Access.vue';
import i18n from '@/i18n';

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
class Routes {
  public static root: RouteConfig = {
    path: '/',
    name: 'Root',
    redirect: '/signin',
    meta: {
      getTitleTranslation: () => i18n.t('views.root.title'),
    }
  };

  public static accessWithCode: RouteConfig =  {
    path: ':accessCode',
    name: 'AccessWithCode',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.access.title'),
    },
    component: Access,
  };

  public static access: RouteConfig =  {
    path: '/access',
    name: 'Access',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.access.title'),
    },
    component: Access,
    children: [
      Routes.accessWithCode
    ]
  };

  public static signin: RouteConfig =  {
    path: '/signin',
    name: 'Sign in',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.signin.title'),
    },
    component: () => import("@/views/Signin.vue")
  };

  public static signup: RouteConfig =  {
    path: '/signup',
    name: 'Sign up',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.signup.title'),
    },
    component: () => import("@/views/Signup.vue")
  };

  public static forgotPasswordRoute: RouteConfig =  {
    path: '/forgotPassword',
    name: 'Forgot password',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.forgotPassword.title'),
    },
    component: () => import('@/views/ForgotPasswordPage.vue')
  };
  
  public static resetPasswordRoute: RouteConfig =  {
    path: '/resetPassword',
    name: 'Reset password',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.resetPassword.title'),
    },
    component: () => import('@/views/ResetPasswordPage.vue')
  };

  public static inviteRegisterRoute: RouteConfig =  {
    path: '/inviteRegister',
    name: 'Register with an invite',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.inviteRegister.title'),
    },
    component: () => import('@/views/InviteRegisterPage.vue')
  };

  public static confirmAccountRoute: RouteConfig =  {
    path: '/confirmAccount',
    name: 'Confirm account',
    meta: { 
      allowAnonymous: true,
      hideMenus: true,
      getTitleTranslation: () => i18n.t('views.confirmAccount.title'),
    },
    component: () => import('@/views/ConfirmAccountPage.vue')
  };

  public static notFound: RouteConfig = {
    path: '*',
    name: 'NotFound',
    redirect: '/',
  };

  public static routes: RouteConfig[] = [
    Routes.root,
    Routes.access,
    Routes.signin,
    Routes.signup,
    Routes.resetPasswordRoute,
    Routes.forgotPasswordRoute,
    Routes.inviteRegisterRoute,
    Routes.confirmAccountRoute,
    Routes.notFound
  ];
}

export default Routes;
