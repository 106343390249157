import ThemeDefault from './default/theme_default';
import ThemeBitville from './bitville/theme_bitville';
import ThemeExscientiaLight from './exscientia/theme_exscientia_light';
import ThemeExscientiaDark from './exscientia/theme_exscientia_dark';
import themeRdcg from './rdcg/theme_rdcg';
import IAppTheme from './IAppTheme';

export default class Themes {

    static themeDefault =  new ThemeDefault();
    static themeBitville = new ThemeBitville();
    static themeExscientiaLight = new ThemeExscientiaLight();
    static themeExscientiaDark = new ThemeExscientiaDark();
    static themeRdcg = new themeRdcg();

    static themes: IAppTheme[] = [
      Themes.themeDefault,
      Themes.themeBitville,
      Themes.themeExscientiaLight,
      Themes.themeExscientiaDark,
      Themes.themeRdcg,
    ];

    static get themeIds(): string[] {
        return this.themes.map(t => t.id);
    }

    static MatchThemeIdFromUrl(): string{
        if (document.location.host.indexOf('localhost') > -1 || document.location.host.indexOf('.ngrok.io') > -1) {
            const matchedThemeByPathname = Themes.themes.find(t => document.location.pathname.startsWith("/" + t.id));
            if (matchedThemeByPathname){
                return matchedThemeByPathname.id;
            }
        }
        const matchedThemeByHostname = Themes.themes
            .find(t => t.matchIfHostNameContainsAny.some(m => document.location.host.indexOf(m) > -1));
        if (matchedThemeByHostname){
            return matchedThemeByHostname.id;
        }
        return 'default';
  }
}