import './theme_bitville.scss';
import IAppTheme from '../IAppTheme';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import { colors } from "vuetify/lib"

export default class ThemeBitville implements IAppTheme {
    id = 'bitville';
    matchIfHostNameContainsAny: string[] = ["bitville"];
    providerName = 'Bitville';
    appName = 'BitLearn';
    cssClassName = 'theme_bitville';
    dark = true;
    menuDark = true;
    toolbarDark = true;
    vuetifyTheme: VuetifyThemeVariant = {
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        primary: "#1976D2",
        secondary: "#424242",
        success: "#4CAF50",
        warning: "#FB8C00",
        toolbar: colors.blue.darken2,
        infoButton: "#2196F3",
    };
}