import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store, { localStorageKey, readFromLocalStorage, saveToLocalStorage } from '@/store';
import AuthTokenExchange from '@/models/AuthTokenExchange';
import AuthenticateRequest from '@/models/AuthenticateRequest';
import ApiService from '@/services/ApiService';
import AccessToken from '@/models/AccessToken';
import PasswordResetRequest from '@/models/PasswordResetRequest';
import InviteRegisterRequest from '@/models/InviteRegisterRequest';
import CreatePasswordResetResponse from '@/models/CreatePasswordResetResponse';
import NotificationStore from './NotificationStore';
import SnackbarNotification, { SnackbarNotificationType } from '@/models/SnackbarNotification';
import router from '@/router'
import Routes from '@/Routes';
import ConfirmAccountRequest from '@/models/ConfirmAccountRequest';
import GoogleAuthTokenVerifyRequest from '@/models/GoogleAuthTokenVerifyRequest';
import AppStore from './AppStore';
import { EmailWithoutPasswordRegisterRequest, EmailWithPasswordRegisterRequest } from '@/models/RegisterRequest';

export interface IAuthStoreState {
  accessToken: AccessToken | null;
  isMsalAuthenticated: boolean;
  isGoogleAuthenticated: boolean;
  msalRedirectPromiseInProgress: boolean;
  googleRedirectPromiseInProgress: boolean;
}

export interface ICredentials {
  email: string;
}

export class PasswordResetTokenExistsException {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

// Hack due to issues
// https://github.com/championswimmer/vuex-module-decorators/issues/131
// https://github.com/championswimmer/vuex-module-decorators/issues/189

const name = 'auth';
if (store && store.state[name]) {
  try{
    store.unregisterModule(name);
  } catch (e){
    console.warn("Unregister store module workaround error, ignoring ...")
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name
})
export class AuthStoreModule extends VuexModule implements IAuthStoreState {
  static modulePath = "auth";
  static baseURL = "/api/account";
  accessToken: AccessToken | null = readFromLocalStorage("accessToken", null, AuthStoreModule.modulePath);
  isMsalAuthenticated = false;
  isGoogleAuthenticated = false;
  msalRedirectPromiseInProgress = false;
  googleRedirectPromiseInProgress = false;

  static handleError(err: any) {
    const errString = err.toString();
    NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, errString));
    console.error(err);
  }

  get isAuthenticated(): boolean {
    return this.isMsalAuthenticated || this.isPasswordAuthenticated || this.isGoogleAuthenticated;
  }

  get isPasswordAuthenticated(): boolean {
    return !!this.accessToken;
  }

  @Action
  async renewAuthTokens() {
    const path = AuthStoreModule.baseURL + "/renewTokens";
    try {
      const authResponse = await ApiService.get(path);
      if (authResponse && (authResponse.status === 201 || authResponse.status === 200)) {
        this.RENEW_AUTH_TOKENS(authResponse.data);
      } else {
        throw new Error('Error when assigning auth tokens');
      }
    } catch (ex) {
      throw ex;
    }
  }
  @Action
  async signIn(authenticateRequest: AuthenticateRequest) {
    const path = AuthStoreModule.baseURL + "/signIn";
    try {
      const authResponse = await ApiService.post<AuthTokenExchange>(path, authenticateRequest);
      if (authResponse && (authResponse.status === 201 || authResponse.status === 200)) {
        this.RENEW_AUTH_TOKENS(authResponse.data);
      } else {
        throw new Error('Error when assigning auth tokens');
      }
    } catch (ex) {
      throw ex;
    }
  }
  @Action
  async registerWithInvite(registerRequest: InviteRegisterRequest): Promise<boolean> {
    const path = AuthStoreModule.baseURL + "/registerWithInvite";
    try {
      const authResponse = await ApiService.post<AuthTokenExchange>(path, registerRequest);
      if (authResponse && (authResponse.status === 201 || authResponse.status === 200)) {
        this.RENEW_AUTH_TOKENS(authResponse.data);
        return true;
      } else {
        throw new Error('Error when assigning auth tokens');
      }
    } catch (ex) {
      AuthStoreModule.handleError(ex);
      return false;
    }
  }
  @Action
  async registerWithEmailWithoutPassword(registerRequest: EmailWithoutPasswordRegisterRequest): Promise<boolean> {
    const path = AuthStoreModule.baseURL + "/registerWithEmail";
    try {
      const authResponse = await ApiService.post<AuthTokenExchange>(path, registerRequest);
      if (authResponse && (authResponse.status === 201 || authResponse.status === 200)) {
        this.RENEW_AUTH_TOKENS(authResponse.data);
        return true;
      } else {
        throw new Error('Error when assigning auth tokens');
      }
    } catch (ex) {
      AuthStoreModule.handleError(ex);
      throw ex;
    }
  }
  @Action
  async registerWithEmailWithPassword(registerRequest: EmailWithPasswordRegisterRequest): Promise<boolean> {
    const path = AuthStoreModule.baseURL + "/register";
    try {
      const authResponse = await ApiService.post<AuthTokenExchange>(path, registerRequest);
      if (authResponse && (authResponse.status === 201 || authResponse.status === 200)) {
        this.RENEW_AUTH_TOKENS(authResponse.data);
        return true;
      } else {
        throw new Error('Error when assigning auth tokens');
      }
    } catch (ex) {
      AuthStoreModule.handleError(ex);
      throw ex;
    }
  }
  @Mutation
  RENEW_AUTH_TOKENS(data: AuthTokenExchange) {
    this.accessToken = AccessToken.fromApi(data.accessToken);
    saveToLocalStorage("accessToken", this.accessToken, AuthStoreModule.modulePath);
  }

  @Mutation
  RESET_AUTH_TOKENS() {
    this.accessToken = null;
    saveToLocalStorage("accessToken", this.accessToken, AuthStoreModule.modulePath);
  }
  @Action
  resetAuthTokens() {
    this.RESET_AUTH_TOKENS();
  }

  @Mutation
  SET_IS_MSAL_AUTHENTICATED(val: boolean) {
    this.isMsalAuthenticated = val;
  }
  @Action
  setIsMsalAuthenticated(val: boolean) {
    this.SET_IS_MSAL_AUTHENTICATED(val);
  }

  @Mutation
  SET_REDIRECT_PROMISE_IN_PROGRESS(val: boolean) {
    this.msalRedirectPromiseInProgress = val;
  }
  @Action
  setMsalRedirectPromiseInProgress(val: boolean) {
    this.SET_REDIRECT_PROMISE_IN_PROGRESS(val);
  }

  @Mutation
  SET_GOOGLE_AUTH_CHECK_IN_PROGRESS(val: boolean) {
    this.googleRedirectPromiseInProgress = val;
  }
  @Action
  setGoogleRedirectPromiseInProgress(val: boolean) {
    this.SET_GOOGLE_AUTH_CHECK_IN_PROGRESS(val);
  }

  @Action
  async sendPasswordRecoveryEmail(email: string): Promise<CreatePasswordResetResponse | PasswordResetTokenExistsException | null> {
    const path = AuthStoreModule.baseURL + "/forgotPassword?email=" + email;
    try {
      const axiosResponse = await ApiService.get(path);
      const response = CreatePasswordResetResponse.fromApi(axiosResponse.data);
      return response;
    } catch (ex: any) {
      if (ex.response?.status === 409) {
        const message = ex.response?.data ?? "";
        return new PasswordResetTokenExistsException(message);
      }
      return null;
    }
  }

  @Action
  async resendPasswordRecoveryEmail(email: string): Promise<boolean> {
    const path = AuthStoreModule.baseURL + "/resendResetPasswordEmail?email=" + email;
    try {
      const axiosResponse = await ApiService.get(path);
      if (axiosResponse && (axiosResponse.status === 201 || axiosResponse.status === 200)) {
        return true;
      }
      return false;
    } catch (ex) {
      console.error('There was a problem sending password recovery email.', ex);
      return false;
    }
  }

  @Action
  async validatePasswordResetToken(resetToken: string): Promise<boolean> {
    const path = AuthStoreModule.baseURL + "/validatePasswordResetToken?token=" + resetToken;
    try {
      const authResponse = await ApiService.get(path);
      if (authResponse && (authResponse.status === 201 || authResponse.status === 200)) {
        return true;
      }
      return false;
    } catch (ex) {
      console.error(ex);
      return false;
    }
  }

  @Action
  async resetPassword(resetRequest: PasswordResetRequest): Promise<boolean> {
    const path = AuthStoreModule.baseURL + "/resetPassword";
    try {
      const authResponse = await ApiService.post<AuthTokenExchange>(path, resetRequest);
      if (authResponse && (authResponse.status === 201 || authResponse.status === 200)) {
        return true;
      }
      return false;
    } catch (ex) {
      AuthStoreModule.handleError("There was a problem updating the password. Check if your link is still valid and passwords are correct.");
      return false;
    }
  }

  @Action
  async getFrontendAccess(): Promise<boolean> {
    const path = "/api/user/frontendAccess";
    try {
      await ApiService.get(path);
      return true;
    } catch (err: any) {
      if (err.response?.data?.error?.code && err.response?.data?.error?.code === "invitation_code_missing") {
        if (router.currentRoute.path !== Routes.confirmAccountRoute.path) {
          router.push({ path: Routes.confirmAccountRoute.path });
        }
      } else {
        console.error(err);
        AuthStoreModule.handleError("Error when getting access to the main app");
      }
      return false;
    }
  }

  @Action
  async resetFrontendAccess() {
    const path = "/api/user/resetFrontendAccess";
    try {
      await ApiService.get(path);
    } catch (ex: any) {
      console.error(ex);
    }
  }

  @Mutation
  SET_IS_GOOGLE_AUTHENTICATED(val: boolean) {
    this.isGoogleAuthenticated = val;
  }

  @Action
  setIsGoogleAuthenticated(val: boolean) {
    this.SET_IS_GOOGLE_AUTHENTICATED(val);
  }

  @Action
  async verifyGoogleAuthToken(verifyTokenRequest: GoogleAuthTokenVerifyRequest) {
    const path = AuthStoreModule.baseURL + "/verifyGoogleAuthToken";
    try {
      const response = await ApiService.post(path, verifyTokenRequest)
      if (response && (response.status === 201 || response.status === 200)) {
        this.SET_IS_GOOGLE_AUTHENTICATED(true)
        return true
      }
      return false
      
    } catch (ex: any) {
      console.error(ex.message)
      return false
    }
  }

  @Action
  async confirmAccount(data: ConfirmAccountRequest): Promise<boolean> {
    const path = "/api/account/confirmAccount";
    try {
      await ApiService.post(path, data);
      return true;
    } catch (err: any) {
      console.error(err);
      AuthStoreModule.handleError("Error when confirming the account. Invitation code could be invalid, expired or already used.");
      return false;
    }
  }
}

const AuthStore = getModule(AuthStoreModule);
export default AuthStore;