import Vue from 'vue'
import Router, { Route } from 'vue-router'

// Routes
import Routes from '@/Routes';

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: Routes.routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

function hasRedirectUrlQueryParam(route: Route) {
  return route.query && route.query.redirectUrl != undefined && route.query.redirectUrl.length > 0
    ? true
    : false
}

router.beforeEach((to, from, next) => {
   if(!hasRedirectUrlQueryParam(to) && hasRedirectUrlQueryParam(from)){
    to.query.redirectUrl = from.query.redirectUrl;
    next({name: to.name as string | undefined, query: to.query});
  } else {
    next()
  }
})

export default router
