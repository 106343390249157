import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store';
import ApiService from '@/services/ApiService';
import Organization from '@/models/Organization';

export interface IOrganizationStoreState {
  organization: Organization | null;
}

// Hack due to issues
// https://github.com/championswimmer/vuex-module-decorators/issues/131
// https://github.com/championswimmer/vuex-module-decorators/issues/189
const name = 'organization'
if (store && store.state[name]) {
  store.unregisterModule(name)
}

@Module({ dynamic: true, namespaced: true, store, name })
export class OrganizationStoreModule extends VuexModule implements IOrganizationStoreState {
  organization: Organization | null = null;
  static baseUrl = "/api/organization";

  static handleError(err: any) {
    const errString = err.response?.data?.error?.message ? err.response.data.error.message : err.toString();
    console.error(err);
  }

  static handleSuccessfulResponse(message: string) {
  }

  @Mutation
  private GET_ORGANIZATION_BY_ACCESS_CODE(data: Organization) {
    const organization = Organization.fromApi(data);
    this.organization = organization;
  }
  @Action
  public async getOrganizationByAccessCode(id: string) {
    const path = OrganizationStoreModule.baseUrl + "/byAccessCode/" + id;
    try {
      const response = await ApiService.get(path);
      this.GET_ORGANIZATION_BY_ACCESS_CODE(response.data);
      delete response.data;
    } catch (err) {
      OrganizationStoreModule.handleError(err);
      throw err;
    }
  }

  @Mutation
  private CLEAR_ORGANIZATION() {
    this.organization = null;
  }
  @Action
  public async clearOrganization() {
    this.CLEAR_ORGANIZATION(); 
  }
}

const OrganizationStore = getModule(OrganizationStoreModule);

export default OrganizationStore;
