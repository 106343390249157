import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store';
import Language from '@/models/Language';
import ApiService from '@/services/ApiService';

export interface ILanguageStoreState {
  language: Language | null;
  languages: Language[];
}

// Hack due to issues
// https://github.com/championswimmer/vuex-module-decorators/issues/131
// https://github.com/championswimmer/vuex-module-decorators/issues/189

const name = 'language';
if (store && store.state[name]) {
  try{
    store.unregisterModule(name);
  } catch (e) {
    console.warn("Unregister store module workaround error, ignoring ...")
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name
})
export class LanguageStoreModule extends VuexModule implements ILanguageStoreState {
  static baseURL = "/api/language";
  language: Language | null = null;
  languages: Language[] = [];

  @Mutation
  private GET_ALL_LANGUAGES(data: Language[]) {
    this.languages = data.map(l => Language.fromApi(l));
  } 
  @Action
  public async getAllLanguages() {
    const path = LanguageStoreModule.baseURL;
    try {
      const response = await ApiService.get(path);
      this.GET_ALL_LANGUAGES(response.data);
    } catch (err){
      const errString = err.toString();
      console.error('Error when doing api request:', errString)
      // proper error handling here
    }
  }
}

const LanguageStore = getModule(LanguageStoreModule);
export default LanguageStore;