
export default class AppConfigFeatures {
  invitationCodes = false;
  organizationAccessCodes: { enabled?: boolean, accessCodeUrlBase?: string } = {};

  static fromApi(data: AppConfigFeatures) {
    const model = new AppConfigFeatures();
    model.invitationCodes = data.invitationCodes ? true : false;
    model.organizationAccessCodes = data.organizationAccessCodes;
    return model;
  }
}