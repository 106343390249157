import { Guid } from 'guid-typescript';

export default class Organization {
  id = Guid.create().toString();
  name = "";
  description = "";
  accessCode: string | null = null;
  visitor: boolean = false;
  deleted = false;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  get descriptionFormatted(): string {
    if (!this.description){
      return "-";
    }
    return this.description;
  }

  get accessCodeFormatted(): string {
    if (!this.accessCode){
      return "-";
    }
    return this.accessCode;
  }

  get accessCodeUrl(): string | null {
    if (!this.accessCode){
      return null;
    }
    // TODO: Get base url from settings
    const accessCodeUrl = "https://app.domain.com/access/" + this.accessCode;
    return accessCodeUrl;
  }

  get accessCodeUrlFormatted(): string {
    if (!this.accessCodeUrl){
      return "-";
    }
    return this.accessCodeUrl;
  }

  public static fromApi(data: Organization): Organization {
    const model = new Organization();
    model.id = data.id;
    model.name = data.name;
    model.description = data.description;
    model.accessCode = data.accessCode;
    model.visitor = data.visitor;
    model.deleted = data.deleted;
    model.createdAt = new Date(data.createdAt);
    model.updatedAt = new Date(data.updatedAt);

    return model;
  }
}