import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store, {localStorageKey, readFromLocalStorage, saveToLocalStorage} from "@/store";
import ApiService from "@/services/ApiService";
import AppConfigFeatures from "@/models/AppConfigFeatures";
import Themes from "@/../../Main/src/themes"
import IAppTheme from '@/../../Main/src/themes/IAppTheme'


export interface IAppStoreState {
  appThemeId: string;
  appThemes: IAppTheme[];
  features: AppConfigFeatures | null;
  defaultLanguageCode: string;
  currentLanguageCode: string;
}

const siteThemeId = Themes.MatchThemeIdFromUrl();

const name = "app";
if (store && store.state[name]) {
  try {
    store.unregisterModule(name);
  } catch (e){
    console.warn("Unregister store module workaround error, ignoring ...")
  }
}

@Module({ 
  dynamic: true, 
  namespaced: true, 
  store, 
  name
})
export class AppStoreModule extends VuexModule implements IAppStoreState {
  static modulePath = "app";
  static baseURL = "/api/config";
  appThemeId: string = readFromLocalStorage("appThemeId", siteThemeId, AppStoreModule.modulePath);
  appThemes: IAppTheme[] = Themes.themes;
  features: AppConfigFeatures | null = null;
  defaultLanguageCode = "en-US";
  currentLanguageCode = readFromLocalStorage("currentLanguageCode", this.defaultLanguageCode, AppStoreModule.modulePath);

  @Mutation
  GET_CONFIG_FEATURES(data: AppConfigFeatures) {
    this.features = AppConfigFeatures.fromApi(data);
  }
  @Action
  async getConfigFeatures() {
    const path = AppStoreModule.baseURL + "/features";
    try {
      const response = await ApiService.get(path);
      if (response) {
        this.GET_CONFIG_FEATURES(response.data);
      } else {
        throw new Error('Error when getting app config features');
      }
    } catch (ex) {
      throw ex;
    }
  }

  @Mutation
  SET_LANGUAGE(val: string) {
    this.currentLanguageCode = val;
    saveToLocalStorage("currentLanguageCode", this.currentLanguageCode, AppStoreModule.modulePath);
  }
  @Action
  async setLanguage(val: string) {
    this.SET_LANGUAGE(val);
  }

  @Mutation
  SET_THEME_ID(themeId: string) {
    const appTheme = Themes.themes.find(at => at.id === themeId)
    if (appTheme !== undefined) {
      this.appThemeId = appTheme.id;
      saveToLocalStorage("appThemeId", appTheme.id, AppStoreModule.modulePath);
    }
  }

  @Action
  setThemeId(themeId: string) {
    this.SET_THEME_ID(themeId)
  } 

  get appTheme(): IAppTheme{
    let appTheme =  Themes.themes.find(at => at.id === this.appThemeId);
    if (!appTheme){
      appTheme = Themes.themes.find(at => at.id === "default");
    }
    if (!appTheme){
      throw Error("Specified theme does not exist, and default theme is missing");
    }
    return appTheme as IAppTheme;
  }

}

const AppStore = getModule(AppStoreModule);
export default AppStore;