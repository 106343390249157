import { MsalPlugin } from "@/plugins/msalPlugin";
import AuthStore from "@/store/AuthStore";
import Routes from "@/Routes";
import VueRouter from "vue-router";

export default class AuthHelper {
  public static isAccessTokenValid = () => {
    const accessToken = AuthStore.accessToken;
    if (!accessToken) return false;

    const currTimestamp = Date.now();

    const nowDate = new Date(currTimestamp);
    const expiresAtDate = new Date(accessToken.expiresAt);

    const differenceBetweenDatesInSec = (+expiresAtDate - +nowDate) / 1000;

    // this method returns false 30sec before the actual expiration just to have some buffer
    return differenceBetweenDatesInSec > 30;
  }

  public static isRenewTokensUrl = (url: string | undefined) => {
    return url ? url.toLowerCase().includes("/account/renewtokens") : false;
  }
}