export default class Language {
    code = "";
    name = "";
    color = "";
  
    constructor() {
      // empty constructor
    }
  
    public static fromApi (data: any): Language {
      const model: Language = new Language();
  
      model.code = data.code;
      model.name = data.name;
      model.color = data.color;
      return model;
    }
  
    get codeFormatted(){
      return this.code;
    }
  }
  