import './theme_rdcg.scss';
import IAppTheme from '../IAppTheme';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import { colors } from "vuetify/lib"

export default class ThemeRdcg implements IAppTheme {
    id = 'rdcg';
    matchIfHostNameContainsAny: string[] = ["rdcg"];
    providerName = 'RDCG';
    appName = 'RDCG Safety Passport';
    cssClassName = 'theme_rdcg';
    dark = false;
    menuDark = false;
    toolbarDark = true;
    legal = {
        privacyPolicyUrl: "https://www.iubenda.com/privacy-policy/98940529/legal",
        cookiePolicyUrl: "https://www.iubenda.com/privacy-policy/98940529/cookie-policy",
        termsUrl: "https://www.iubenda.com/terms-and-conditions/98940529"
    };
    vuetifyTheme: VuetifyThemeVariant = {
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        primary: "#073c87",
        secondary: "#424242",
        success: "#4CAF50",
        warning: "#FB8C00",
        toolbar: colors.blue.darken2,
        infoButton: "#2196F3",
    };
}