
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import { Route, RouteConfig } from 'vue-router';
import Routes from '@/Routes';
import LanguageStore from '@/store/LanguageStore';
import Language from '@/models/Language';
import AppStore from '@/store/AppStore';

@Component({
  name: "LocaleSelect",
  components: {
   
  },
})
export default class LocaleSelect extends Vue {

  @Prop({ default: false })
  dark!: boolean;

  get currentLanguageCode(): string {
    return AppStore.currentLanguageCode;
  }

  get currentLanguage(): Language | null {
    var lang = this.selectableLanguages.find(l => l.code === this.currentLanguageCode);
    return lang
      ? lang
      : null;
  }

  get selectableLanguages(): Language[] {
    const allLanguages: Language[] = LanguageStore.languages;
    // TODO: Filter down allLanguages to selectable languages
    // return [];
    return allLanguages;
  }

  onLanguageOptionClick(language: Language): void {
    AppStore.setLanguage(language.code);
  }

}
