
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})

export default class PageLoading extends Vue {
  defaultLoadingText = "Loading..."

  @Prop({ default: "" })
  loadingText!: string;

  get visibleLoadingText() {
    return this.loadingText ? this.loadingText : this.defaultLoadingText;
  }
}
