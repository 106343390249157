import './theme_default.scss';
import IAppTheme from '../IAppTheme';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import { colors } from "vuetify/lib"

export default class ThemeDefault implements IAppTheme {
    id = 'default';
    matchIfHostNameContainsAny: string[] = ["localhost", ".ngrok.io", "127.0.0.1"];
    providerName = 'Bitville';
    appName = 'Learnie';
    cssClassName = 'theme_default';
    dark = false;
    menuDark = false;
    toolbarDark = true;
    vuetifyTheme: VuetifyThemeVariant = {
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        primary: "#2196F3",
        secondary: "#424242",
        success: "#4CAF50",
        warning: "#FB8C00",
        toolbar: colors.blue.darken2,
        infoButton: "#2196F3"
    };
}