import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { MsalPlugin, MsalPluginOptions } from '@/plugins/msalPlugin';
import ApiService from './services/ApiService';
import BaseService from './services/BaseService'
import { FirebaseAuthPlugin, FirebaseAuthPluginOptions } from './plugins/firebaseAuthPlugin'
import i18n from './i18n'

const options: MsalPluginOptions = {
  clientId: process.env.VUE_APP_MSAL_CLIENT_ID ?? "",
  loginAuthority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY ?? "",
  cacheLocation: 'localStorage',
  postLogoutRedirectUri: window.location.origin,
  loginRedirectUri: window.location.origin
};

Vue.use(new MsalPlugin(), options);

// Firebase configuration
const firebaseConfig: FirebaseAuthPluginOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY as string,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN as string,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: process.env.VUE_APP_FIREBASE_APP_ID as string,
};

Vue.use(new FirebaseAuthPlugin(), firebaseConfig)

ApiService.EnableCustomInterceptors(Vue.prototype.$msal, router);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
