import AuthStore from "@/store/AuthStore";
import { initializeApp } from "@firebase/app";
import * as firebase from "firebase/auth";
import { signInWithPopup, signInWithRedirect, getRedirectResult, signOut, setPersistence, browserLocalPersistence } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import Vue, { VueConstructor, PluginObject } from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $firebaseAuth: FirebaseAuthPlugin;
  }
}

export interface FirebaseAuthPluginOptions {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export let firebaseAuthPluginInstance: FirebaseAuthPlugin;

export class FirebaseAuthPlugin implements PluginObject<FirebaseAuthPluginOptions> {
  private pluginOptions: FirebaseAuthPluginOptions = {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: ""
  };

  public isAuthenticated = false;

  public install(vue: VueConstructor, options?: FirebaseAuthPluginOptions): void {
    if (!options) {
      throw new Error("FirebaseAuthPluginOptions must be specified");
    }
    // console.log('registering a plugin, options:', options);
    this.pluginOptions = options;

    initializeApp(this.pluginOptions);
    this.getIsAuthenticated();
    
    AuthStore.setGoogleRedirectPromiseInProgress(true);
    const auth = firebase.getAuth();
    getRedirectResult(auth).then(redirectPromiseResponse => {
      if (redirectPromiseResponse !== null) {
        AuthStore.setIsGoogleAuthenticated(this.isAuthenticated);
      }
    })
    .catch(e => console.warn(e))
    .finally(() => AuthStore.setGoogleRedirectPromiseInProgress(false))

    firebaseAuthPluginInstance = this;
    vue.prototype.$firebaseAuth = Vue.observable(firebaseAuthPluginInstance);
  }

  public async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    const auth = firebase.getAuth();

    try {
      await signInWithRedirect(auth, provider)
    } catch (error: any) {
      const errorMessage = error.message;
      console.error("Error signing in with Google:", errorMessage)
      return false
    }
  }

  public async  signOutWithGoogle() {
    const auth = firebase.getAuth();
    try {
      await signOut(auth)
    } catch (err: any) {
      console.error("Signout error:", err.message)
    } 
  }

  public async getUserIdToken(user: any) {
    return await user.getIdToken();
  }

  public getIsAuthenticated() {
    firebase.getAuth().onAuthStateChanged((user: any) => {
      if (user) {
        // User is present.
        this.isAuthenticated = true;
      }
      else {
        // User is not present.
        this.isAuthenticated = false
      } 
      AuthStore.setIsGoogleAuthenticated(this.isAuthenticated);
    })
  }
}
