import AppStore from '@/store/AppStore';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { VSnackbar, VBtn, VIcon, VList } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'
import fi from 'vuetify/src/locale/fi'
import de from 'vuetify/src/locale/de'
import fr from 'vuetify/src/locale/fr'
import pl from 'vuetify/src/locale/pl'
import ro from 'vuetify/src/locale/ro'
import nl from 'vuetify/src/locale/nl'
import es from 'vuetify/src/locale/es'
import pt from 'vuetify/src/locale/pt'
import it from 'vuetify/src/locale/pt'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VList
  }
})
// Init VuetifyToast
Vue.use(VuetifyToast, {
  x: 'right', // default
  y: 'bottom', // default
  color: 'info', // default
  icon: 'mdi-information',
  iconColor: '', // default
  classes: [
      'body-2'
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: true, // default
  vertical: false, // default
  queueable: false, // default
  showClose: true, // default
  closeText: '', // default
  closeIcon: 'mdi-close', // default
  closeColor: '', // default
  slot: [], //default
  shorts: {
      custom: {
          color: 'purple'
      }
  },
  property: '$toast' // default
})

const appTheme = AppStore.appTheme;

export default new Vuetify({
  lang: {
    locales: { fi, de, fr, pl, ro, nl, es, pt, it },
    current: 'en',
  },
  theme: {
    dark: appTheme.dark ? true : false,
    options: {
      customProperties: true
    },
    themes: {
      dark: appTheme.dark ? appTheme.vuetifyTheme : {},
      light: appTheme.dark ? {} : appTheme.vuetifyTheme
    }
  }
});
