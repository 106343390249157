import AuthHelper from "@/helpers/AuthHelper";
import { MsalPlugin } from "@/plugins/msalPlugin";
import AuthStore from "@/store/AuthStore";
import Axios, { AxiosResponse } from "axios";
import BaseService from './BaseService';
import VueRouter from "vue-router";
import * as firebase from "firebase/auth";

export default class ApiService extends BaseService {
  public static EnableCustomInterceptors(msalPlugin: MsalPlugin, router: VueRouter): void {
    ApiService.EnableCustomRequestInterceptor(msalPlugin);
    ApiService.EnableCustomResponseInterceptor(msalPlugin, router);
  }

  public static EnableCustomRequestInterceptor(msalPlugin: MsalPlugin): void {
    Axios.interceptors.request.use(async requestConfig => {
      const isRenewTokensUrl = AuthHelper.isRenewTokensUrl(requestConfig.url);
  
      if (!AuthStore.isAuthenticated || isRenewTokensUrl) {
        return requestConfig;
      }
  
      let token = null;
  
      if (AuthStore.isMsalAuthenticated) {
        token = await msalPlugin.acquireToken();
      }
      
      if (AuthStore.isGoogleAuthenticated) {
        const user =  await firebase.getAuth().currentUser;
        if (user) {
          token = await user.getIdToken();
        }
      }
  
      if (AuthStore.isPasswordAuthenticated) {
        const tokenIsValid = AuthHelper.isAccessTokenValid();
        if (tokenIsValid) {
          token = AuthStore.accessToken?.token;
        } else {
          try {
            await AuthStore.renewAuthTokens();
            token = AuthStore.accessToken?.token;
          } catch(e) {
            await AuthStore.resetAuthTokens();
            return;
          }
        }
      }
  
      if (token && requestConfig && requestConfig.headers) {
        requestConfig.headers['Authorization'] = 'Bearer ' + token;
      }
  
      return requestConfig;
    }, async (error) => {
      Promise.reject(error);
    });
  }

  public static EnableCustomResponseInterceptor(msalPlugin: MsalPlugin, router: VueRouter): void {
    Axios.interceptors.response.use(response => {
      return response;
    }, async (error) => {
      if (!error.response || error.response.status !== 401 || !AuthStore.isAuthenticated) {
        return Promise.reject(error);
      }
  
      return Promise.reject(error);
    });
  }

  public static async getMock(path: string): Promise<AxiosResponse> {
    return await Axios.get(BaseService.baseURL + path + ".json");
  }

  public static async get(path: string): Promise<AxiosResponse> {
    return await Axios.get(BaseService.externalApiBaseURL + path, { withCredentials: true });
  }

  public static async post<T>(path: string, data?: any): Promise<AxiosResponse<T>> {
    return await Axios.post(BaseService.externalApiBaseURL + path, data, { 
      withCredentials: true, 
      headers: {
        'Content-Type': 'application/json'
      } 
    });
  }

  public static async delete<T>(path: string): Promise<AxiosResponse<T>> {
    return await Axios.delete(BaseService.externalApiBaseURL + path, { withCredentials: true });
  }

  public static async put<T>(path: string, data?: any): Promise<AxiosResponse<T>> {
    return await Axios.put(BaseService.externalApiBaseURL + path, data, { 
      withCredentials: true, 
      headers: {
        'Content-Type': 'application/json'
      } 
    });
  }

  public static async postFiles<T>(path: string, data?: any): Promise<AxiosResponse<T>> {
    return await Axios.post(BaseService.externalApiBaseURL + path, data, { 
      withCredentials: true, 
      headers: {
        'Content-Type': 'multipart/form-data'
      } 
    });
  }
}
