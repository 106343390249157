
import SnackbarNotification from "@/models/SnackbarNotification";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "SnackbarQueue",
})
export default class SnackbarQueue extends Vue {
  processing = false;
  timeoutId: number | undefined = undefined;

  @Watch("items")
  onItemsChange() {
    this.processItems();
  }

  @Prop({ default: false })
  absolute!: boolean;

  @Prop({ default: false })
  autoHeight!: boolean;

  @Prop({ default: false })
  bottom!: boolean;

  @Prop({ default: "white" })
  closeButtonColor!: string;

  @Prop({ default: "mdi-close" })
  closeButtonIcon!: string;

  @Prop({ required: true })
  items!: SnackbarNotification[];

  @Prop({ default: false })
  left!: boolean;

  @Prop({ default: false })
  multiLine!: boolean;

  @Prop({ default: "white" })
  nextButtonColor!: string;

  @Prop({ default: "More" })
  nextButtonCountText!: string;

  @Prop({ default: "Next" })
  nextButtonText!: string;

  @Prop({ default: false })
  right!: boolean;

  @Prop({ default: 8000 })
  timeout!: number;

  @Prop({ default: false })
  top!: boolean;

  @Prop({ default: false })
  vertical!: boolean;

  processItems() {
    this.processing = true;

    if (this.items && Array.isArray(this.items) && this.items.length > 0) {
      const item = this.items[0];
      return (this.timeoutId = window.setTimeout(() => {
        this.removeItem(item.id);
      }, this.timeout));
    }

    this.processing = false;
  }

  removeItem(id: string) {
    if (!this.timeoutId) {
      return;
    }
    clearTimeout(this.timeoutId);

    this.$emit("remove", id);

    if (this.items.length > 0) {
      return this.processItems();
    }
  }
}
