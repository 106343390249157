import './theme_exscientia_base.scss';
import './theme_exscientia_light.scss';
import IAppTheme from '../IAppTheme';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import { colors } from "vuetify/lib"
import IAppThemeSpecificComponent from '../IAppThemeSpecificComponent';
import ExscientiaBanner from "./components/ExscientiaBanner.vue"

export default class ThemeExscientiaLight implements IAppTheme {
    id = 'exscientia_light';
    matchIfHostNameContainsAny: string[] = ["exscientia"];
    providerName = 'Exscientia';
    appName = 'Exscientia Academy';
    cssClassName = 'theme_exscientia_light';
    dark = false;
    menuDark = false;
    toolbarDark = false;
    vuetifyTheme: VuetifyThemeVariant = {
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        primary: "#FF6600",
        secondary: "#424242",
        success: "#FF6600",
        warning: "#FB8C00",
        green: "#009999",
        orange: "#FF6600",
    };
    contentBanner: IAppThemeSpecificComponent = {
        component: ExscientiaBanner
    }
}